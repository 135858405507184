<template>
  <div class="tablistcontent">
    <Motion v-if="title=='红十字运动知识'"></Motion>
    <Rccdevelop v-else-if="title=='中国红十字会发展历程'"></Rccdevelop>
    <Sichuanrcdevelop v-else></Sichuanrcdevelop>
  </div>
</template>

<script>
import Motion from './motion'
import Rccdevelop from './rccdevelop'
import Sichuanrcdevelop from './sichuanrcdevelop'
export default {
  components : {
    Motion,
    Rccdevelop,
    Sichuanrcdevelop
  },
  name: 'Tablistcontent',
  props: {
      title: {
          type: String,
          default: ""
      }
  }
}
</script>

<style lang="less" scoped>
.tablistcontent {
    min-height: 1211px;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
    padding: 0 136px 59px 120px;
}
</style>
