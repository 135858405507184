<template>
  <div class="motion">
      <div class="title">
          <h2>{{motioncontent.title || ""}}</h2>
          <TitleLine width="150px"></TitleLine>
      </div>
      <div class="motion-origin">
        <!-- <div class="motion-title">1、红十字运动 - 起源</div> -->
        <div class="motion-content">
         <p v-html="motioncontent.text?motioncontent.text:''"></p>
         <!-- <p>
            红十字运动起源于战场救护，瑞士人亨利• 杜南先生（1828－1910）是红十字运动的创始人。
            亨利•杜南先生于1828年5月8日诞生于日内瓦。他的父亲是个成功的商人，在日内瓦很有声望；母亲温认化诚，十分注意对他的早期教育。
            亨利•杜南先生在青年时期已有了很高的道德准则，成为日内瓦救济委员会的成员；他常常给穷人、病人及遭受其他痛苦的人精神上的安慰和物质的上的帮助。
            正当亨利•杜南先生在商业生涯上走向成功的时候，一场战挣--索尔弗利诺战争改变了他的一生。
            1859年6月24日，奥地利陆军与法国--撒丁（意大利邦国之一）联军30多万人激战于意大利北部伦巴第地区一个叫索尔弗利诺的村庄，因缺乏医疗救护，士兵伤亡惨重，约有4万多名受伤濒危之人被遗弃在战场。6月25日，正在欧洲各地从事私人商务活动的亨利•杜南先生途经此地，为惨象所震动，当即决定将个人的事业放置一边，投入战场救护。
            他联系当地一所教堂作为临时救护所，并与法军军医总监取得联系，释放数名奥军军医俘虏，负责治疗工作。
            他还发动邻近的卡斯蒂廖村的村民参加救护，并劝导参与救护的人不带成见地医治所有的伤病员。
         </p>
         <p>
            这次经历深深触动了亨利•杜南先生的良知和思想。
            他回到日内瓦后立刻撰写了《索尔弗利诺回忆录》一书，以直观感人的方式叙沭了在索尔弗利诺见到的情景，并于1862年11月自费出版。
           《索尔弗利诺回忆录》问世后，在欧洲各国引起强烈反响。
            他在书中提出两项重要建议：  
         </p>
         <p>一是在各国设立全国性的志愿伤兵救护组织，平时开展救护技能练习，战时增援军队医疗工作；</p>
         <p>二是签订一份国际公约给予军事医务人员和医疗机构及各国志愿的伤兵救护组织以中立的地位。</p>
         <p>
            他的建议，得到日内瓦的4位知名的公民--日内瓦公共福利会会长莫瓦尼埃（Moynier）、杜福尔将军（Dufour）、阿皮亚（Appia）医生和莫诺瓦（Maunoir）医生的赞赏和支持。
            1863年2月9日，他们5人在瑞士日内瓦宣告成立"伤兵救护国际委员会"(又称"日内瓦5人委员会")，1875年改名为"红十字国际委员会"。
            亨利•杜南先生为实现其理想，在"伤兵救护国际委员会"成立后，继承努力向欧洲一些国家的君主和证府呼吁，并得到了支持。1863年10月26日，日内瓦国际会议召开。
            来自16个国家和4个私人组织的36名代表（均来自欧洲国家）参加了这次会议。会议由"伤兵救护国际委员会"召集和主持。
            10月29日，会议通过了10项决议。决议的主要内容除包括亨利•杜南先生在《索尔弗利诺回忆录》中提出的两项重要建议外，还有采用白底红十字臂章作为救护人员的保护性标志。
            1864年3月8日，在普鲁士与丹麦之间爆发的日勒苏益格战役中，佩戴红十字臂章的救护人员第一次在战场上出现，并提供人道服务。
         </p>
         <p>
            1864年8月8日－22日，在日内瓦召开了外交会议(正式名称为"关于中立化在战地服务的军队医务部门的国际会议")。
            8月22日，参加会议的12个国家的正式代表签署了第一个日内瓦公约--《关于改善战地陆军伤者境遇之日内瓦公约》。
            公约共有10项条款，包括1863年日内瓦国际会议决议的主要内容。
            公约规定了救护车、军队医院和医务人员，包括志愿人员和随军牧师应被视为中立而受到保护和尊重；提出"受伤或患病的战斗员，不论属何国籍，都应得到收容和保护"这一重要原则；
            公布使用白底红十字标志的旗帜和臂章以区别军队医院和医务人员。
            公约最后呼吁各国证府批准加入这一公约。 
            从此，红十字运动作为一个国际性的运动开始发展起来，并得到国际法的保障。 
         </p> -->
        </div>
      </div>
  </div>
</template>

<script>
import TitleLine  from '@/components/TitleLine'
import apiUrls from '@api'
export default {
  components: {
    TitleLine
  },
  name: 'Motion',
  props : {
      title: {
          type: String,
          default: ""
      }
  },
  data() {
    return {
    motioncontent: ""  
    }
  },
  created() {
     this.getmotionnews() 
  },
  methods: {
     getmotionnews () {
      apiUrls.getMotion({node: 'hszydzs'}).then(res=>{
      this.motioncontent= res.results.data
      })  
     }
  }
}
</script>

<style lang="less" scoped>
.motion {
    height: 100%;
    .title {
        font-size: 23px;
        color: #333333;
        font-weight: bolder;
        text-align: center;
        overflow: hidden;
        h2 {
        margin-top: 41px;
        margin-bottom: 19px;
        }
    }
    .motion-origin {
        .motion-title {
            color: #D1292E;
            font-size: 18px;
            font-weight: bolder;
            margin-bottom: 28px;
            margin-top: 54px;
        }
    }
    .motion-content {
        p {
            text-indent: 2em;
            font-size: 16px;
            color: #666666;
            line-height: 36px;
        }
    }
    .motion-form {
        .form-title {
            color: #D1292E;
            font-size: 18px;
            font-weight: bolder;
            margin-bottom: 28px;
            margin-top: 47px;
        } 
        .form-content {
            p {
             color: #666666;
             line-height: 36px;
            }
        }
    }
}
</style>
